#root {
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: rgb(19,24,29);
}

#root>div, .app>div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app {
  text-align: center;
}

.loading-indicator {
  width: 100%;
  padding: 50px;
  font-size: 26px;
  color: lightgray;
}

.empty-data-indicator {
  width: 100%;
  padding: 50px;
  font-size: 26px;
  color: red;
}

h1 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(25, 39, 52,.30);
  align-content: center;
}

h2 {
  color: #fff;
}

/* game table */
.games-container {
  margin-top: 20px;
  width: 90vw;
  overflow-x: hidden;
  height: fit-content;
}

thead {
  background-color: rgba(125, 139, 152,.30);
}

th {
  color: #eee;
  padding: 10px;
}

td {
  color: #ddd;
  border-bottom: 1px solid rgba(125, 139, 152,.30);
  height: 100px;
}

.row-game .name {
  font-weight: 600;
}

.row-game .logo {
  object-fit: contain;
  max-width: 100px;
  max-height: 100px;
}

.row-game .langs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
}

.row-game .langs-container img {
  max-width: 30px;
}

/* actions on game records */

.actions {
  display: none;
  justify-content: center;
  gap: 10px;
}

.button {
  border-radius: 5px;
  border: 0;
  background: white;
  font-size: 15px;
  padding: 7px 30px;
  cursor: pointer;
}

/* filter */
.filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-container .text-search {
  display: block;
  width: 90vw;
  margin-top: 30px;
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
}

.filter-container .filter-choice-container {
  width: 90vw;
  margin-top: 10px;
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(99, 99, 99, .3);
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;

  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.filter-container .filter-choice-container .filter-choice {
  text-align: left;
  min-width: 200px;
}

.filter-container .filter-choice-container .filter-choice h3 {
  margin: 10px;
  color: lightgray;
}

.filter-container .filter-choice-container .filter-choice input {
  margin-bottom: 10px;
}

.filter-container .filter-choice-container .filter-choice label {
  margin-left: 10px;
}

.filter-container .filter-choice-container .filter-choice label .game-count {
  margin-left: 3px;
  font-size: 14px;
  color: lightblue;

}

/* paginator */
.paginator {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: white;
}

.paginator button {
  font-size: 16px;
}

.paginator select {
  font-size: 16px;
  padding: 5px;
}

.api-call {
  color: gray;
}